import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PageTransition from 'gatsby-plugin-page-transitions';
import { StaticQuery, graphql } from 'gatsby';
import Footer from '../components/common/footer';
import { AppearText, } from '../components/common/appear';
import P from '../components/common/p';
import SeoImage from '../assets/images/seo/about.jpg';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 180px;
  }
`;


const FooterWrapper = styled.div`
    margin-top: 140px;
    padding-bottom: 60px;
    width: 100%;
    @media (max-width: 600px) {
        margin-top: 60px;
    }
`;

const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;

const H3 = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #0064BA;
    margin-top: 61px;
    margin-bottom: 40px;
    @media (max-width: 600px) {
        font-size: 28px;
        line-height: 38px;
        margin-top: 41px;
        margin-bottom: 0px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Item = styled.div`
    width: 50%;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

const ContactPage = ({ data }) => (
    <PageTransition>
        <>
            <Helmet>
                <title>{data.markdownRemark.frontmatter.title_kh} - Blue Ocean Insurance Broker</title>
                <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="default"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, user-scalable=no"
                />
                <meta
                    property="og:type"
                    content="website"
                />
                <meta
                    property="og:url"
                    content="https://boinsurancebroker.com/kh/contact"
                />

                <meta property="og:title" content={data.markdownRemark.frontmatter.title_kh + ' - Blue Ocean Insurance Broker'} />
                <meta property="og:image" content={SeoImage} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://boinsurancebroker.com/kh/contact" />
                <meta property="twitter:title" content={data.markdownRemark.frontmatter.title_kh + ' - Blue Ocean Insurance Broker'} />
                <meta property="twitter:image" content={SeoImage} />
            </Helmet>
            <Page>
                <H1>
                    <AppearText>
                        {data.markdownRemark.frontmatter.title_kh}
                    </AppearText>
                </H1>
                <Row>
                    <Item>
                        <H3>
                            <AppearText>
                                {data.markdownRemark.frontmatter.address_title_kh}
                            </AppearText>
                        </H3>
                        <P><AppearText>
                            <pre
                                dangerouslySetInnerHTML={{
                                    __html: data.markdownRemark.frontmatter.address.replace('\n', '<br>')
                                }}
                                style={{
                                    whiteSpace: 'pre-line',
                                }}
                            /></AppearText>
                        </P>
                    </Item>
                    <Item>
                        <H3><AppearText>{data.markdownRemark.frontmatter.contact_title_kh}</AppearText></H3>
                        <P><AppearText>
                            Phone: <a
                                href={'tel:' + data.markdownRemark.frontmatter.phone}
                            >&nbsp;{data.markdownRemark.frontmatter.phone}</a><br />
                            Email:
                            <a
                                href={'mailto:' + data.markdownRemark.frontmatter.email}
                            >
                                &nbsp;{data.markdownRemark.frontmatter.email}
                            </a>
                        </AppearText></P>
                    </Item>
                    <Item>
                        <H3><AppearText>{data.markdownRemark.frontmatter.map_title_kh}</AppearText></H3>
                        <P><AppearText>
                            <a
                                href={data.markdownRemark.frontmatter.mapstring}
                                target="_blank"
                            >
                                {data.markdownRemark.frontmatter.maplinktext_kh}
                            </a>
                        </AppearText></P>

                    </Item>
                </Row>
                <FooterWrapper>
                    <Footer noPadding />
                </FooterWrapper>
            </Page>
        </>
    </PageTransition>
)

export default function myContact(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    markdownRemark(frontmatter: {templateKey: {eq: "contact-page"}}) {
                        frontmatter {
                            title
                            title_kh
                            address_title
                            address_title_kh
                            contact_title
                            contact_title_kh
                            map_title
                            map_title_kh
                            address
                            phone
                            email
                            mapstring
                            maplinktext
                            maplinktext_kh
                        }
                    }
                }
            `}
            render={data => <ContactPage data={data} {...props} />}
        />
    )
};
